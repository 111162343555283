<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.program_name')"
              :placeholder="$t('labels.program_name')"
              name="name"
              sort-name="name"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 135px">
            <SelectPos
              :label="$t('labels.apply')"
              :placeholder="$t('labels.apply')"
              name="id_pos"
              sort-name="id_pos"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.start')"
              :placeholder="$t('labels.start')"
              name="start_time"
              sort-name="start_time"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter
              :label="$t('labels.end')"
              :placeholder="$t('labels.end')"
              name="end_time"
              sort-name="end_time"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilterFromTo
              :label="$t('labels.spend')"
              :placeholder="$t('labels.spend')"
              name="spend"
              sort-name="spend"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilterFromTo
              :label="$t('labels.accrue_point')"
              :placeholder="$t('labels.accrue_point')"
              name="point"
              sort-name="point"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter
              :label="$t('labels.creator_name')"
              :placeholder="$t('labels.creator_name')"
              name="identity_name"
              sort-name="identity_name"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.time')"
              :placeholder="$t('labels.time')"
              :options="programTimeOptions"
              name="type"
              sort-name="type"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th role="columnheader" class="text-center" style="width: 120px">
            <SelectFilter
              :label="$t('labels.status')"
              :placeholder="$t('labels.status')"
              :options="yesNoOptions"
              name="status"
              sort-name="status"
              has-sort
              :sorting="filters.sort_by"
              @onFilter="onFilterChange"
              @onSort="onSortChange"
            />
          </th>
          <th>
            <v-btn small color="success" @click="showDialogAddProgram">
              {{ $t("labels.add") }}
            </v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(item, index) in items"
          :key="`${index}_${item.id}`"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.pos_code }}</td>
          <td>{{ formatDateTime(item.start_time) }}</td>
          <td>{{ formatDateTime(item.end_time) }}</td>
          <td>{{ formatNumber(item.spend) }}</td>
          <td>{{ formatNumber(item.point) }}</td>
          <td>{{ item.identity_name }}</td>
          <td>
            <div v-if="item.status" :class="getProgramText(item).color">
              {{ getProgramText(item).text }}
            </div>
          </td>
          <td>
            {{ item.status ? "Yes" : "No" }}
            <!--<v-switch class="switch-center" v-model="item.status" :value="1" @change="switchStatus(item)"
                              :key="`s_${item.id}_${item.status}`" :disabled="item.type === 0"></v-switch>-->
          </td>
          <td>
            <v-btn
              v-if="item.status && item.type"
              color="error"
              x-small
              @click="programCancel(item)"
              >Hủy</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="text-center mt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="4"
      ></v-pagination>
    </div>

    <v-dialog v-model="addProgramDialog" persistent max-width="400px">
      <AccruePointProgramCreate
        v-if="addProgramDialog"
        @close="hideDialogAddProgram"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "AccruePointProgram",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectPos: () => import("@/components/table/SelectPos"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    AccruePointProgramCreate: () =>
      import("@/components/pos/AccruePointProgramCreate"),
  },
  data: () => ({
    items: [],
    filters: {},
    page: 1,
    totalPage: 1,
    isLoading: false,
    addProgramDialog: false,
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    programTimeOptions() {
      return [
        {
          text: "Đang diễn ra",
          color: "success--text",
          value: 2,
        },
        {
          text: "Sắp diễn ra",
          color: "primary--text",
          value: 1,
        },
        {
          text: "Đã kết thúc",
          color: "error--text",
          value: 0,
        },
      ];
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showDialogAddProgram() {
      this.addProgramDialog = true;
    },
    hideDialogAddProgram() {
      this.addProgramDialog = false;
    },
    getProgramText(item) {
      return this.programTimeOptions.find((p) => p.value === item.type);
    },
    async programCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_program"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-accrue-point", {
          id: item.id,
        });
        this.isLoading = false;
        this.getList();
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async switchStatus(item) {
      if (!confirm(this.$t("messages.are_you_sure_update_status_program"))) {
        this.getList();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-accrue-point-update-status", {
          id: item.id,
          status: item.status ? 1 : 0,
        });
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/pos-accrue-point", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
